import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 442px;
  background: #ffffff;
  border: 1px solid #ebedf0;
  box-shadow: 0px 0px 16px rgba(71, 108, 240, 0.2);
  margin: 0 auto;
  .empty-svg {
    width: 80px;
    height: 80px;
    fill: var(--color_body_text);
    opacity: 0.4;
  }
  .close-tip {
    margin-top: 30px;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: var(--color_text);
    font-weight: 500;
  }
  .close-describe {
    margin: 16px 0 24px;
    color: var(--color_body_text);
    font-size: 14px;
    line-height: 20px;
    opacity: 0.4;
  }
  .back-home {
    color: var(--color_button);
    cursor: pointer;
    display: flex;
    justify-content: center;
    .arrow {
      display: inline-block;
      margin-left: 4px;
      width: 20px;
      height: 20px;
      fill: var(--color_button);
    }
  }
  @media screen and (max-width: 750px) {
    box-shadow: none;
    border: none;
  }
`;
