import styled from 'styled-components';

export default styled.div`
  background: url('https://b.yzcdn.cn/public_files/6f0d836093ca7d7db3043e9f82fd69b7.png');
  height: 100vh;
  .recruit-page {
    max-width: ${(props) => (props.isDesignModel ? '100%' : props.isClose ? '628px' : '524px')};
    /* max-width: 524px; */
    height: 100%;
    margin: 0 auto;
    margin-top: 68px;
  }

  .pc-banner {
    img {
      width: 100%;
    }
  }

  .recruit-page-container {
    padding: 0 0 0 0;
    margin-top: 0px;
    margin-bottom: 40px;
  }

  .content-text {
    margin-top: 30px;
    padding: 0 24px;
  }

  .rich-edit-content {
    margin-top: 30px;
  }

  .application-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 64px;
    .zent-checkbox-label {
      color: #15161b;
    }
    .apply-btn {
      margin-top: 16px;
      width: 100%;
      margin-bottom: 32px;
    }
  }

  .mobile-banner {
    display: none;
  }

  .default-rule-container {
    .rule-title {
      margin: 40px 0;
      text-align: center;
      font-weight: 600;
      color: #15161b;
    }
    .rule-line {
      font-size: 14px;
      font-weight: 400;
      color: #15161b;
      line-height: 36px;
    }
  }

  .distribution-logo {
    padding-top: 0px;
  }

  @media screen and (max-width: 750px) {
    background: ${(props) =>
      props.isClose ? '#ffffff' : 'https://b.yzcdn.cn/public_files/6f0d836093ca7d7db3043e9f82fd69b7.png'};
    .distribution-logo {
      display: block;
      padding-bottom: 24px;
      margin: 0;
    }
    .recruit-page-container {
      padding: 0px;
      border: none;
    }
    .mobile-banner {
      display: ${(props) => (props.isPcStyle ? 'none' : 'block')};
      img {
        width: 100%;
      }
    }
    .pc-banner {
      display: ${(props) => (props.isPcStyle ? 'block' : 'none')};
    }
  }
`;
