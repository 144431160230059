import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Button, FullScreenLoading, Checkbox } from 'zent';
import get from 'lodash/get';
import { Message } from '../../../util/postmessage';
import RecruitPage from '../components/recruit-page';
import distributionApi from '../../../api/distribution';
import { affiliateStatus } from '../constant/config';
import LocalStorage from '../../../util/local-storage';
import { AFFILIATE_SUB_TRACK } from '../../../util/local-storage/config';
import ClosePage from './close-page';
import Style from './style';
import { useRootContext } from 'client/pages/context/root';
import { IdentityList } from 'const/account';
import { openApplyAffiliateDialog, openQuitTipeDialog } from 'client/components/page/apply-affiliate-dialog';
import { AFFILIATE_CENTER_PAGE } from 'const/distribution';

const Recruit = (props) => {
  const { history, initialData = {} } = props;
  const { kdtId, design, designStyle, affiliateSubTrack, userInfo = {} } = initialData;
  const [config, setConfig] = useState({});
  const [loading, setLoading] = useState(false);
  const [auditStatus, setAuditStatus] = useState({});
  const [settlementDate, setSettlementDate] = useState('');
  const [defaultGroup, setDefaultGroup] = useState({});
  const [affiliateSwitch, setAffiliateSwitch] = useState(null);
  const isDesignModel = Number(design) === 1;
  const isPcStyle = designStyle === 'pc-style';
  const shopName = get(props, 'initialData.shopSetting.name', '');
  const intl = useIntl();
  const [privacyState, setPrivacyState] = useState(false);
  const { theme, setLoginDialogInfo } = useRootContext();
  const isLogin = userInfo.customerId;
  const isThridLogin = initialData.from === 'thridLogin';
  const defaultConfig = {
    h5Banner: 'https://b.yzcdn.cn/public_files/034705dc69c521f5c47874f2c4e222ab.png',
    pcBanner: 'https://b.yzcdn.cn/public_files/034705dc69c521f5c47874f2c4e222ab.png',
    bgColor: '#fff',
    buttonText: intl.formatMessage({ id: 'c8a34ec4116e469aa8305e733e3b97b8', defaultMessage: '申请成为分销员' }),
  };
  const getAdvancedSetting = () => {
    return distributionApi.getAdvancedSetting({ kdtId }).then((res) => {
      const { settlementDate: lastDay, affiliateSwitch } = res || {};
      setSettlementDate(lastDay);
      setAffiliateSwitch(affiliateSwitch);
    });
  };

  const handleNewMessage = (options, type) => {
    if (type === 'affiliateMessage') {
      const body = document.querySelector('body');
      body.style.cssText = 'pointer-events: none;';
      options && setConfig(JSON.parse(options));
    }
  };

  const getRecruitingPoster = () => {
    distributionApi
      .getRecruitingPoster({
        kdtId,
        posterType: 'recruiting',
      })
      .then((res) => {
        setConfig(res);
      });
  };

  const getShopDefaultGroupRule = () => {
    distributionApi.getShopDefaultGroupRule({ kdtId }).then((res) => {
      setDefaultGroup(res || {});
    });
  };

  const getPersonalInfo = () => {
    setLoading(true);
    distributionApi
      .getPersonalInfo()
      .then((res) => {
        setAuditStatus(res || {});
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const saveSubTrack = () => {
    if (affiliateSubTrack) {
      LocalStorage.set(AFFILIATE_SUB_TRACK, affiliateSubTrack, 24);
    }
  };

  const reportAffiliateSubTrack = () => {
    const { customerId } = userInfo || {};
    const trackInfo = LocalStorage.get(AFFILIATE_SUB_TRACK);
    if (customerId && trackInfo) {
      distributionApi.reportAffiliateInviteLinkClick({
        tracking: trackInfo,
      });
    }
  };

  useEffect(() => {
    getAdvancedSetting();
    getShopDefaultGroupRule();
    if (!isDesignModel) {
      distributionApi
        .isLogin()
        .then((res) => {
          const { isLogin: loginStatus } = res || {};
          return Promise.resolve(loginStatus);
        })
        .then((loginStatus) => {
          if (loginStatus) {
            getPersonalInfo();
          }
        });
      getRecruitingPoster();
      saveSubTrack(); // 保存下级跟踪
      reportAffiliateSubTrack(); // 上报分享
      return;
    }
    const message = new Message(props, handleNewMessage);
    return () => {
      message.removeListenMessage();
    };
  }, []);

  const identities = userInfo?.identities ?? [];
  const isAffiliateRole = identities.includes(IdentityList.Affiliate);
  const { status } = auditStatus || {};
  const isReject = affiliateStatus.rejected === status;

  const applyBecomeDistributor = () => {
    if (!isLogin) {
      setLoginDialogInfo({
        visible: true,
        isFromAffiliate: true,
      });
      return;
    }
    if (!isAffiliateRole) {
      openApplyAffiliateDialog({
        intl,
        history,
        initialData,
        theme,
        title: intl.formatMessage({ id: 'c8a34ec4116e469aa8305e733e3b97b8', defaultMessage: '申请成为分销员' }),
      });
      return;
    }
    history.push(AFFILIATE_CENTER_PAGE);
  };

  useEffect(() => {
    if (isThridLogin) {
      applyBecomeDistributor();
    }
  }, []);

  const { buttonText, description, h5Banner, pcBanner, bgColor } = config;
  return (
    <Style isDesignModel={isDesignModel} isPcStyle={isPcStyle} isClose={!affiliateSwitch}>
      <FullScreenLoading loading={loading} icon='circle' />
      <RecruitPage shopName={shopName}>
        {affiliateSwitch !== null &&
          (affiliateSwitch ? (
            <div style={{ background: bgColor || defaultConfig.bgColor }}>
              <div className='pc-banner'>
                <img src={pcBanner || defaultConfig.pcBanner} alt={buttonText} />
              </div>
              <div className='mobile-banner'>
                <img src={h5Banner || defaultConfig.h5Banner} alt={buttonText} />
              </div>
              <div className='content-text'>
                {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
                {!description && (
                  <div className='default-rule-container'>
                    <p className='rule-title'>
                      {intl.formatMessage(
                        {
                          id: 'join_distributor_program',
                          description: `欢迎加入 ${shopName} 分销员计划`,
                        },
                        {
                          shopName,
                        },
                      )}
                    </p>
                    <p className='rule-line'>
                      1.
                      {defaultGroup?.commissionValue &&
                        intl.formatMessage(
                          {
                            id: 'promoter_commission_value',
                            description: `申请成为推广员，您只需要在任何渠道分享产品和网站，买家购买后，可获得产品实付金额的
                                                ${defaultGroup?.commissionValue / 10 || 0}%佣金。`,
                          },
                          {
                            commissionValue: defaultGroup?.commissionValue / 10 || 0,
                          },
                        )}
                      {!defaultGroup?.commissionValue &&
                        intl.formatMessage({
                          id: 'default_commission_text',
                          description: `申请成为推广员，您只需要在任何渠道分享产品和网站，买家购买后，可获得产品实付金额的
                                                相应佣金。`,
                        })}
                    </p>
                    <p className='rule-line'>
                      2.
                      {intl.formatMessage(
                        {
                          id: 'invitee_discount',
                          description: `受邀者通过您的分享链接，同时也可获得${defaultGroup?.discountTitle}折扣。`,
                        },
                        {
                          discountTitle: defaultGroup?.discountTitle,
                        },
                      )}
                    </p>
                    <p className='rule-line'>
                      3.
                      {intl.formatMessage({
                        id: 'will_not_commission',
                        description: `发生退款的订单，将不计入佣金计算。`,
                      })}
                    </p>
                    <p className='rule-line'>
                      4.
                      {intl.formatMessage(
                        {
                          id: 'settle_commission_day',
                          description: `每月${settlementDate}日，我们将对您的佣金进行结算。`,
                        },
                        {
                          settlementDate,
                        },
                      )}
                    </p>
                    <p className='rule-line'>
                      5.
                      {intl.formatMessage({
                        id: 'join_distribution_plan',
                        description: `目前已有不少分销员通过计划获得可观的收益，快来加入分销计划吧!`,
                      })}
                    </p>
                  </div>
                )}
                <div className='application-wrapper'>
                  <div className='agree-privacy'>
                    <Checkbox
                      checked={privacyState}
                      onChange={(e) => {
                        setPrivacyState(e?.target?.checked);
                      }}
                    >
                      {intl.formatMessage({
                        id: '5d4abacb4d1a4e558424cf9248cefef2',
                        defaultMessage: '我已知悉并同意分销员计划',
                      })}
                    </Checkbox>
                  </div>
                  <Button
                    type='primary'
                    className='apply-btn'
                    onClick={applyBecomeDistributor}
                    disabled={!privacyState}
                  >
                    {buttonText || defaultConfig.buttonText}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <ClosePage />
          ))}
      </RecruitPage>
    </Style>
  );
};

export default Recruit;
