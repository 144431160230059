import React from 'react';
import { useIntl } from 'react-intl';
import Style from './style';

const ClosePage = () => {
  const intl = useIntl();
  return (
    <Style>
      <svg aria-hidden='true' className='icon empty-svg'>
        <use xlinkHref='#iconic-kong' />
      </svg>
      <div className='close-tip'>
        {intl.formatMessage({
          id: 'f8c78e20e67c434e9e7e5a2820f51044',
          defaultMessage: '分销员计划已下线',
        })}
      </div>
      <div className='close-describe'>
        {intl.formatMessage({
          id: 'ba278ae7779b4ae0b7c612532f9f0e45',
          defaultMessage: '分销员计划已下线，你可回到首页逛逛',
        })}
      </div>
      <div
        className='back-home'
        onClick={() => {
          window.location.href = '/';
        }}
      >
        {intl.formatMessage({ id: '11858eaafcd444069a3f15c9a4510f8b', defaultMessage: '回到首页' })}
        <svg aria-hidden='true' className='icon arrow'>
          <use xlinkHref='#iconic-jiantouyou' />
        </svg>
      </div>
    </Style>
  );
};

export default ClosePage;
